import { isNil } from 'lodash';

let facebookPixel = undefined;

export const initFacebookPixel = (initId, config = {}) => {
  if (!facebookPixel) {
    facebookPixel = require('react-facebook-pixel').default;
  }
  if (isNil(initId)) return undefined;
  facebookPixel.init(initId, {}, config);
};

export const trackPageView = () => {
  if (!facebookPixel) return undefined;
  facebookPixel.pageView();
};

export const track = (title, data = undefined) => {
  if (!facebookPixel) return undefined;
  facebookPixel.track(title, data);
};