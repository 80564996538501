import React from 'react';
import MailChimpForm from "./mailchimp-form";

export default () => (
  <div className="section section--register">
    <div className="section__inner section__inner--register">
      <h2 className="section__heading">Sign up for our newsletter.</h2>
      <h3 className="section__subheading subheading--grey">
        Register below for updates on upcoming iOS and Android app releases.
      </h3>
      <MailChimpForm 
        action="register_for_newsletter"
        actionButtonLabel="Register now!"
      />
    </div>
  </div>
);