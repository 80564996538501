import React, { useState } from "react";
import PropTypes from "prop-types";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { mobileDeviceType } from '../utilities/navigator';
import { track as trackFacebookEvent } from '../utilities/facebook-pixel';
import "./mailchimp-form.scss";

const MailChimpForm = ({ action, actionButtonLabel, modifier }) => {
  const [submission, updateSubmission] = useState({ msg: null, result: null });
  const handleSubmit = async e => {
    e.preventDefault();
    const email = e.currentTarget[0].value;
    const result = await addToMailchimp(email, {
      DTYPE: mobileDeviceType(),
      RREASON: action,
      CCODE: "unknown"
    });
    updateSubmission(result);
    trackCustomEvent({
      category: "signup",
      action: `${action}_button_click`,
    });
    trackFacebookEvent(
      "Lead", {
        action
      }
    );
  };
  const { result, msg } = submission;
  return (
    <div className={`mailchimp-form mailchimp-form--${modifier}`}>
      {result !== "success" && (
        <form className={`mailchimp-form__form mailchimp-form__form--${modifier}`} onSubmit={handleSubmit}>
          <input
            type="email"
            name="EMAIL"
            className={`mailchimp-form__input mailchimp-form__input--${modifier} required email`}
            placeholder="Email address"
            required
          />
          <input
            type="submit"
            value={actionButtonLabel}
            name="subscribe"
            className={`mailchimp-form__submit-button mailchimp-form__submit-button--${modifier}`}
          />
        </form>
      )}
      {result !== null && (
        <div
          className={`mailchimp-form__response mailchimp-form__response--${modifier} mailchimp-form__response--${result}`}
          dangerouslySetInnerHTML={{ __html: msg }}
        />
      )}
    </div>
  );
};

MailChimpForm.propTypes = {
  action: PropTypes.string.isRequired,
  actionButtonLabel: PropTypes.string.isRequired,
  modifier: PropTypes.string.isRequired
};

MailChimpForm.defaultProps = {
  modifier: "default"
};

export default MailChimpForm;